import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";

const KampaniaYoutubeAdsPage = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Kampanie reklamowe Linkedin Ads",
        description:
          "Reklamuj się na Linkedin. Kampanie na Linkedin pozwolą Ci na precyzyjne i skuteczne dotarcie do grupy docelowej. Wykorzystaj Linkedin Ads w dzialaniach HR oraz w obszarze reklam B2B. Efektywne kampanie z When. Umów się na bezpłatną konsultację marketingową. ",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          Kampanie <br /> <span>LinkedIn Ads</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#ppc">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Specjalizacje:</p>
            <ul>
              <li>
                <Link to="/audyt-kampanii-google-facebook-ads/">
                  Audyt kampanii <br /> Google/Facebook Ads
                </Link>
              </li>
              <li>
                <Link to="/kampanie-googleads-search/">
                  Kampanie Google Ads search
                </Link>
              </li>
              <li>
                <Link to="/kampanie-wideo-na-youtube/">
                  Kampanie Youtube Ads
                </Link>
              </li>
              <li>
                <Link to="/kampanie-w-sieci-reklamowej-gdn/">Kampanie GDN</Link>
              </li>
              <li>
                <Link to="/kampanie-produktowe-product-listing-ads/">
                  Kampanie PLA
                </Link>
              </li>
              <li>
                <Link to="/tiktok-ads/">Kampanie TikTok Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-facebook-ads/">Kampanie Facebook Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-na-instagramie/">
                  Kampanie na Instagramie
                </Link>
              </li>
              <li className="current">
                <a href="#home">Kampanie LinkedIn</a>
              </li>
              <li>
                <Link to="/banery-statyczne-i-dynamiczne/">
                  Banery statyczne i dynamiczne
                </Link>
              </li>
              <li>
                <Link to="/kampanie-zagraniczne/">
                  Kampanie PPC na rynki zagraniczne
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <section className="single-offer-section-text-left single-offer-section-text-left--cat-2">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Bądź tam, gdzie Twoi klienci
                </h2>
                <p className="text-col__desc">
                  LinkedIn to międzynarodowy portal o charakterze
                  biznesowo-zawodowym, który gromadzi profesjonalistów z różnych
                  branż. Z portalu korzysta w Polsce już ponad 2 mln osób i choć
                  kojarzy się on jako portal do budowania swojego profilu
                  zawodowego, z przeznaczeniem dla rekruterów, to również
                  doskonały system dla reklamodawców.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/linkedin-campaign-img-1.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-big-img single-offer-section-big-img--linkedin-ads">
        <div className="row">
          <div className="col-xl-7 col-lg-8 offset-lg-4">
            <h2 className="single-offer-section-big-img__title">
              Dlaczego LinkedIn Ads?
            </h2>
            <div className="image-container">
              <img
                className="single-offer-section-big-img__image image image--desktop"
                src={require("../assets/images/linkedin-ads-graph.jpg")}
                alt=""
              />
              <img
                className="single-offer-section-big-img__image image image--mobile"
                src={require("../assets/images/linkedin-ads-graph-mobile.jpg")}
                alt=""
              />
              <p className="image-container__text text text--1">
                Precyzyjne <br /> targetowanie B2B
              </p>
              <p className="image-container__text text text--2">
                Unikalne <br /> formaty reklam
              </p>
              <p className="image-container__text text text--3">
                Biznesowy <br /> charakter portalu
              </p>
              <p className="image-container__text text text--4">
                Reklamy <br /> dla rekruterów
              </p>
              <p className="image-container__text text text--5">
                Przejrzyste <br /> wyniki
              </p>
              <p className="image-container__text text text--6">
                Reklamy do skrzynki nadawczej
              </p>
              <ul className="big-bullets list-mobile">
                <li>Precyzyjne targetowanie B2B</li>
                <li>Unikalne formaty reklam</li>
                <li>Biznesowy charakter portalu</li>
                <li>Reklamy dla rekruterów</li>
                <li>Przejrzyste wyniki</li>
                <li>Reklamy do skrzynki nadawczej</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-right">
        <div className="row">
          <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/linkedin-campaign-img-2.jpg")}
                  alt=""
                />
              </div>
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">Targetowanie</h2>
                <p className="text-col__desc mb-40">
                  Główną zaletą LinkedIn Ads są <span>opcje targetowania.</span>{" "}
                  Ponieważ cała platforma koncentruje się na zawodowej strefie
                  życia, tak samo dzieje się z kierowaniem reklam. Oprócz
                  standardowych opcji kierowania opartych o demografię, mamy
                  możliwość dotarcia z reklamą na przykład do:
                </p>
                <ul className="big-bullets">
                  <li>osób na wysokim szczeblu w hierarchii firmy</li>
                  <li>osób w określonym zawodzie</li>
                  <li>pracowników konkretnej branży</li>
                  <li>osób o określonych umiejętnościach</li>
                  <li>osób w konkretnych grupach na LinkedIn</li>
                  <li>absolwentów wybranych uczelni.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-three-images">
        <div className="row">
          <div className="col-xl-7 col-lg-8 offset-lg-4">
            <h2 className="single-offer-section-icons-timeline__title">
              Różnorodne formaty
            </h2>
            <p>
              Pomimo skupienia się na sferze biznesowej reklamy na LinkedIn
              bardzo przypominają te na Facebooku. Mogą przybrać kilka znanych
              nam już form:
            </p>
            <div className="images-container">
              <div className="row">
                <div className="col-sm-4">
                  <div className="inner">
                    <img
                      src={require("../assets/images/linkedin-format-1.jpg")}
                      alt=""
                      className="img-fluid inner__image"
                    />
                    <p className="inner__title">
                      Post <br /> z pojedynczym <br /> zdjęciem
                    </p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="inner">
                    <img
                      src={require("../assets/images/linkedin-format-2.jpg")}
                      alt=""
                      className="img-fluid inner__image"
                    />
                    <p className="inner__title">
                      Post <br /> z karuzelą
                    </p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="inner">
                    <img
                      src={require("../assets/images/linkedin-format-3.jpg")}
                      alt=""
                      className="img-fluid inner__image"
                    />
                    <p className="inner__title">
                      Post <br /> z video
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-container">
              <p>
                Dostępna jest też, analogiczna do tej z portalu Facebook
                kampania leadowa z formularzem. Ciekawym uzupełnieniem
                reklamowego portfolio jest <span>kampania inMail</span>{" "}
                pozwalająca na wysłanie naszej reklamy bezpośrednio do
                linkedinowej skrzynki odbiorczej potencjalnego klienta.
                <br />
                <br />W zależności od celu, kampania LinkedIn może skupiać się
                na:
              </p>
              <ul className="big-bullets">
                <li>Budowaniu świadomości marki, </li>
                <li>
                  Generowaniu zainteresowania produktem lub usługą: <br />
                  <span>-</span> na odwiedzinach witryny <br />
                  <span>-</span> interakcjach z reklamą <br />
                  <span>-</span> wyświetleniach wideo
                </li>
                <li>
                  Generowanie konwersji: <br />
                  <span>-</span> pozyskiwaniu potencjalnych klientów <br />
                  <span>-</span> generowaniu konwersjach w witrynie <br />
                  <span>-</span> pozyskiwaniu kandydatów do pracy
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-contact">
        <div className="form-wrapper">
          <h2 className="single-offer-section-contact__title mb-40">
            Skontaktuj się z nami! <br />
            Wypełnij <span>formularz</span>
          </h2>
          <ContactFormDark locales={locales["pl"]} messageRequired={false} />
        </div>
        <img
          className="img-fluid single-offer-section-contact__hand"
          src={require("../assets/images/home-contact-hand-right.png")}
          alt=""
        />
      </section>
    </Layout>
  );
};
export default KampaniaYoutubeAdsPage;
